import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, CHANGE, CHANGE_SUCCESS, CHANGE_FAIL, WAIT, WAIT_SUCCESS, WAIT_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import Cookies from "js-cookie";

const currentLocale = Cookies.get("i18next") || "en";

export const loadUser = () => async (dispatch, getState) => {
  dispatch({
    type: LOGIN,
  });
  let user = localStorage.getItem("POVPixUser");

  if (user) {
    user = JSON.parse(user);
    return dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        payload: user,
        token: localStorage.getItem("POVPixToken")
      },
    });
  } else {
    return dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  return dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const logIn = (data, close) => (dispatch) => {
  dispatch({ type: LOGIN });

  postRequest(data, { locale: currentLocale }, undefined, undefined, endpoints.auth.login)
    .then((response) => {
      const { data } = response
      notification.success({ message: data.message, style: { marginTop: "15vh" } })
      close && close()
      dispatch({ type: LOGIN_SUCCESS, payload: data });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const signUp = (data, navigate, close) => (dispatch) => {
  dispatch({ type: WAIT });

  postRequest(data, { locale: currentLocale }, undefined, undefined, endpoints.auth.signup)
    .then((response) => {
      const { data } = response
      close && close()
      if(data.login) {
        notification.success({ message: data.message, style: { marginTop: "15vh" } })
        window.postMessage(data.token);
        dispatch({ type: WAIT_SUCCESS });
        return dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            payload: data.payload,
            token: data.token
          }
        });
      }
      navigate && navigate(`/verify/${data.token}`)
      return dispatch({ type: WAIT_SUCCESS });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
};

export const verifyAccount = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  postRequest(data, { locale: currentLocale }, undefined, undefined, endpoints.auth.verifyAccount)
    .then((response) => {
      const { data } = response;
      notification.success({ message: data.message, style: { marginTop: "15vh" } })
      window.postMessage(data.token);
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          payload: data.payload,
          token: data.token
        }
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message || err.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const changePassword = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: CHANGE });
  const token = useState().auth?.token
  const role = token.split(" ")[0]
  var end;

  switch (role) {
    case "Admin": end = endpoints.admin.changePassword; break;
    case "User": end = endpoints.user.changePassword; break;
    default: break;
  }

  putRequest(data, undefined, undefined, token, end)
    .then((response) => {
      const { data } = response;
      notification.success({ message: "Password Changed Successfully", style: { marginTop: "15vh" } })
      return dispatch({
        type: CHANGE_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: CHANGE_FAIL,
      });
    });
};

export const editProfile = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: CHANGE });
  const token = useState().auth?.token

  putRequest(data, undefined, undefined, token, endpoints.auth.editProfile)
    .then((response) => {
      const { data } = response;
      notification.success({ message: "Profile Edited Successfully", style: { marginTop: "15vh" } })
      return dispatch({
        type: CHANGE_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: CHANGE_FAIL,
      });
    });
};

export const forgotPassword = (data, navigate) => (dispatch) => {
  dispatch({
    type: WAIT,
  })
  postRequest(data, { locale: currentLocale }, undefined, undefined, endpoints.auth.forgotPassword)
    .then((response) => {
      const { data } = response;
      notification.success({ message: data?.message, style: { marginTop: "15vh" } })
      navigate && navigate("/login");
      return dispatch({
        type: WAIT_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}

export const resetPassword = (data, navigate) => (dispatch) => {
  dispatch({
    type: WAIT,
  })
  postRequest(data, { locale: currentLocale }, undefined, undefined, endpoints.auth.resetPassword)
    .then((response) => {
      const { data } = response;
      notification.success({ message: data?.message, style: { marginTop: "15vh" } })
      navigate && navigate("/login");
      return dispatch({
        type: WAIT_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}